<script lang="ts" setup>
import { computed } from "vue";
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { ScreenOptionWithNextStep, SingleChoiceScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import generators from "~/resources/onboarding/generators";
import { useOnboardingStore } from "~/stores/useOnboardingStore";
import AppAlertDanger from "~/components/app/AppAlertDanger.vue";

interface Props {
  screen: SingleChoiceScreen;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();
const { addActionEvent } = useTrackingStore();

const useGridLayout = computed(() => props.screen.optionsLayout === "Grid");

function selectOption(option: ScreenOptionWithNextStep<string>) {
  emit("next", {
    params: {},
    nextScreenId: option.nextStep,
  });

  addActionEvent({
    name: props.screen.eventName,
    selection: [option.trackingName],
    screenType: props.screen.type,
  });
}

const { $pinia } = useNuxtApp();

const { registrationParams, multiChoiceSelectedOptions, singleChoiceSelectedOptions } = useOnboardingStore($pinia);

const _screen = computed(() => {
  if (!props.screen.generatorFunctionName) {
    return props.screen;
  }

  const generator = generators[props.screen.generatorFunctionName];

  const result = generator({ registrationParams, multiChoiceSelectedOptions, singleChoiceSelectedOptions });

  if (!result) {
    // Todo: Log to Sentry
    return null;
  }
  return {
    ...props.screen,
    titleTranslationKey: result.titleTranslationKey,
    options: result.options,
  } as SingleChoiceScreen;
});
</script>

<template>
  <div
    v-if="_screen"
    :class="{
      'tw-flex tw-flex-col tw-gap-16': useGridLayout,
    }"
  >
    <h1 class="text-header-md">
      {{ $t(_screen.titleTranslationKey) }}
    </h1>
    <div
      class="gap-3 !tw-grid"
      :class="{
        '!tw-grid-cols-2': useGridLayout,
      }"
    >
      <OnboardingBtn
        v-for="(option, key) in _screen.options"
        :key="key"
        :emoji="option.emoji"
        :translation-key="option.translationKey"
        :description-translation-key="option.descriptionTranslationKey"
        :is-column="useGridLayout"
        @click="selectOption(option)"
      />
    </div>
  </div>
  <AppAlertDanger v-else class="my-3"> {{ $t(`system.general.message.unknown_error`) }} </AppAlertDanger>
</template>
